@import 'common/media';
html, body {
  margin: 0;
  height: 100%;
  width: 100%;
  lang: ”ru-RU”;
  position: relative;
}

html{
  @extend %clearfix;
  font-size: 16px;
    @include laptop{
      font-size: 16px;
    }
    @include tablet{
      font-size: 15px;
    }
    @include phone{
        font-size: 14px;
  }

}



@function rem($px){
  @return $px / 16px + 0rem;
}



%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@import 'common/greating';
@import 'common/media';
