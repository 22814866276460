@charset "UTF-8";
html, body {
  margin: 0;
  height: 100%;
  width: 100%;
  lang: ”ru-RU”;
  position: relative; }

html {
  font-size: 16px; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    html {
      font-size: 16px; } }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    html {
      font-size: 15px; } }
  @media screen and (max-width: 480px) {
    html {
      font-size: 14px; } }

html:after {
  content: "";
  display: table;
  clear: both; }

@font-face {
  font-family: roboto-regular;
  src: url(../fonts/roboto-regular.ttf); }

@font-face {
  font-family: roboto-medium;
  src: url(../fonts/roboto-medium.ttf); }

@font-face {
  font-family: fontawesome-webfont;
  src: url(../fonts/fontawesome-webfont.ttf); }

@font-face {
  font-family: Firasanslight;
  src: url(../fonts/Firasanslight.ttf); }

@font-face {
  font-family: Firasansbold;
  src: url(../fonts/Firasansbold.ttf); }

@font-face {
  font-family: Firasansregular;
  src: url(../fonts/Firasansregular.ttf); }

@font-face {
  font-family: SWSimp;
  src: url(../fonts/SWSimp.ttf); }

@font-face {
  font-family: open-sans-light;
  src: url(../fonts/open-sans-light.ttf); }

@font-face {
  font-family: MyriadPro-Regular;
  src: url(../fonts/MyriadPro-Regular.otf); }

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 80;
  position: absolute; }

#status {
  width: auto;
  height: 40.625rem;
  position: absolute;
  background: url(../img/Loader.gif) no-repeat center center;
  background-size: cover;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 81; }

.wrap__index {
  display: none; }

.loader-bar {
  width: 1%;
  height: 2px;
  background: #ECA839;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 82; }

.loader {
  background: none repeat scroll 0 0 #000;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 9999; }

.loader .loader_inner {
  width: auto;
  height: 40.625rem;
  background-image: url("../img/Loader.gif");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #000;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto; }

.cont__ff {
  height: 260rem;
  width: 100%; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .cont__ff {
      height: 350rem; } }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .cont__ff {
      height: 410rem; } }
  @media screen and (max-width: 480px) {
    .cont__ff {
      height: 370rem; } }

.header {
  height: 23.75rem;
  width: 100%; }

.head_fon {
  background-color: #3d107b;
  background-repeat: repeat-x;
  height: 23.75rem;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

@keyframes bg {
  0% {
    transform: translate3d(0, 0, 0); }
  100% {
    transform: translate3d(-3056px, 0, 0); } }

.menu_top {
  width: 100%;
  height: 1.25rem;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: relative;
  display: block;
  margin: 0 auto;
  padding: 1% 0 0 0; }
  @media screen and (max-width: 480px) {
    .menu_top {
      display: none; } }

.menu_t {
  width: 100%;
  height: 1.25rem;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: relative;
  display: block;
  margin: auto;
  padding: 0;
  text-align: center; }

.menu_tt {
  padding: 5% 2% 0 1%;
  display: inline;
  list-style-type: none; }

.menu_ttt {
  text-decoration: none;
  color: #fff;
  font-family: MyriadPro-Regular, sans-serif;
  font-size: 0.875rem;
  line-height: 1.1; }
  .menu_ttt:hover {
    color: #f9cb74; }

.head_text {
  width: 60%;
  height: 80%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: relative;
  display: inline-block;
  float: left; }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .head_text {
      width: 100%; } }
  @media screen and (max-width: 480px) {
    .head_text {
      width: 100%; } }

.head_pic {
  width: 40%;
  height: 80%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: relative;
  display: inline-block;
  float: right; }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .head_pic {
      display: none; } }
  @media screen and (max-width: 480px) {
    .head_pic {
      display: none; } }

.wrap_head_text {
  position: absolute;
  display: inline-block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 7% auto 0 auto;
  height: 18.75rem;
  width: 100%; }

.dobr {
  color: #ffffff;
  font-family: Firasanslight, sans-serif;
  font-size: 1rem;
  text-align: center;
  margin: 0 0 5px 0; }

.text_proekt_comp {
  color: #f9cb74;
  font-family: Firasansregular, sans-serif;
  font-size: 2.5rem;
  text-align: center;
  margin: 0; }

.text_proekt_comp:before,
.text_proekt_comp:after {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 3.75rem;
  height: 3px;
  margin: 0 10px;
  border-top: 1px solid #f9cb74; }

.wrap_line {
  width: 65%;
  height: 5px;
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0 auto; }

.line {
  border-bottom: 1px solid #fff;
  width: 100%;
  height: 5px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0 auto; }

.head_text1 {
  color: #ffffff;
  font-family: open-sans-light, sans-serif;
  font-size: 0.8125rem;
  text-align: center;
  margin: 14px auto 40px auto;
  width: 55%;
  height: auto;
  line-height: 18px; }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .head_text1 {
      width: 80%;
      margin: 14px auto 10px auto; } }
  @media screen and (max-width: 480px) {
    .head_text1 {
      width: 80%;
      margin: 14px auto 10px auto; } }

.wrap_btn1 {
  width: 70%;
  height: 2.5rem;
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 5px auto 0 auto; }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .wrap_btn1 {
      margin: 20px auto 0 auto; } }

.btn1 {
  width: 11.25rem;
  height: 2.5rem;
  border-radius: 3px;
  color: #fff;
  font-family: open-sans-light, sans-serif;
  display: inline-block;
  background: #ffa800;
  outline: none;
  text-decoration: none;
  border: none;
  position: absolute;
  margin: 0 auto;
  text-align: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }
  .btn1:hover {
    color: #000;
    cursor: pointer; }

.wrap_img {
  position: absolute;
  display: inline-block;
  top: 0;
  left: -150px;
  bottom: 0;
  right: 0;
  margin: 0% auto 0 auto;
  height: 15.625rem;
  width: auto; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .wrap_img {
      margin: 17% auto 0 auto; } }

.img_head {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  margin: 0 auto;
  height: auto;
  width: 80%; }

.str_bottom {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: relative;
  margin: 0 auto;
  height: 1.25rem;
  width: 100%; }
  .str_bottom i {
    color: #ffa800;
    text-align: center;
    font-size: 2.5rem; }

.wrap_str_bottom {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: relative;
  margin: 0 auto;
  height: 1.25rem;
  width: 100%;
  text-align: center; }

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  vertical-align: middle; }

ul li {
  list-style-type: none;
  vertical-align: middle; }

.Sidebar {
  width: 30%;
  height: 78%;
  position: relative;
  display: inline-block;
  float: left;
  overflow: hidden; }

.nav_uslugi {
  width: 90%;
  height: 20px;
  background-color: #f9cb74;
  position: relative;
  display: block;
  margin: 0 auto; }

.nav_uslugi1 {
  width: 90%;
  height: 20px;
  background-color: #f9cb74;
  position: relative;
  display: block;
  margin: 0 auto; }
  @media screen and (max-width: 480px) {
    .nav_uslugi1 {
      height: 40px; } }

.zag_usl {
  color: #02046c;
  font-family: Firasansregular, sans-serif;
  text-align: center;
  font-size: 1.125rem;
  padding: 0 0 0 0;
  margin: 30px 0 0px 0; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .zag_usl {
      font-size: 1rem; } }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .zag_usl {
      font-size: 1rem; } }
  @media screen and (max-width: 480px) {
    .zag_usl {
      font-size: 1rem; } }

.usl_spisok {
  color: #02046c;
  font-family: Firasanslight, sans-serif;
  font-size: 1rem;
  margin: 5% 0 0 10%; }

.usl_item {
  margin: 2% 0; }

.Content_block {
  width: 70%;
  height: 78%;
  position: relative;
  display: inline-block;
  float: right; }

.Block1 {
  width: 100%;
  position: relative;
  height: 710px; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .Block1 {
      height: 1430px; } }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .Block1 {
      height: 1380px; } }
  @media screen and (max-width: 480px) {
    .Block1 {
      height: 1350px; } }

.zag1 {
  color: #02046c;
  font-family: Firasansbold, sans-serif;
  text-align: center;
  font-size: 1.25rem;
  text-transform: uppercase;
  padding: 0 0 0 0;
  font-weight: bold;
  margin: 30px 0 30px 0; }
  @media screen and (max-width: 480px) {
    .zag1 {
      font-size: 1.125rem;
      margin: 20px 0 20px 0; } }

.zag1:before,
.zag1:after {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 3.75rem;
  height: 3px;
  margin: 0 10px;
  border-top: 1px solid #02046c; }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .zag1:before,
    .zag1:after {
      display: none; } }
  @media screen and (max-width: 480px) {
    .zag1:before,
    .zag1:after {
      display: none; } }

.Block1_1 {
  width: 48%;
  height: 210px;
  border: 1px solid #02046c;
  display: inline-block;
  position: relative;
  float: left;
  margin: 0 0% 1% 0; }
  .Block1_1:hover {
    border: 1px solid #f9cb74;
    cursor: pointer; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .Block1_1 {
      float: none;
      display: block;
      width: 98%;
      margin: 0 0% 3% 0; } }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .Block1_1 {
      float: none;
      display: block;
      width: 98%;
      margin: 0 0% 3% 0; } }
  @media screen and (max-width: 480px) {
    .Block1_1 {
      float: none;
      display: block;
      width: 98%;
      margin: 0 0% 3% 0; } }

.Block1_2 {
  width: 48%;
  height: 210px;
  border: 1px solid #02046c;
  display: inline-block;
  position: relative;
  float: right;
  margin: 0 1% 1% 0; }
  .Block1_2:hover {
    border: 1px solid #f9cb74;
    cursor: pointer; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .Block1_2 {
      float: none;
      display: block;
      width: 98%;
      margin: 0 1% 3% 0; } }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .Block1_2 {
      float: none;
      display: block;
      width: 98%;
      margin: 0 1% 3% 0; } }
  @media screen and (max-width: 480px) {
    .Block1_2 {
      float: none;
      display: block;
      width: 98%;
      margin: 0 1% 3% 0; } }

.block1_1_pic {
  width: 45%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: relative;
  display: inline-block; }

.wrap_block1_1_img {
  position: relative;
  display: inline-block;
  top: 0;
  left: 0px;
  bottom: 0;
  right: 0;
  margin: 0;
  height: 90%;
  width: 40%;
  vertical-align: bottom; }

.img_block1_1 {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  margin: auto;
  height: auto;
  width: 100%; }

.zag_Block1_1 {
  color: #02046c;
  font-family: Firasansbold, sans-serif;
  text-align: center;
  font-size: 0.875rem;
  padding: 1% 0 0 0;
  font-weight: bold;
  margin: 0; }

.wrap_block1_1_txt {
  position: relative;
  display: inline-block;
  top: 0;
  left: 0px;
  bottom: 0;
  right: 0;
  margin: 0;
  height: 90%;
  width: 60%;
  float: right; }
  @media screen and (max-width: 480px) {
    .wrap_block1_1_txt {
      height: 90%; } }

.spisok_b1_1 {
  margin: auto;
  font-family: open-sans-light, sans-serif;
  line-height: 1.5;
  width: 100%;
  font-size: 0.875rem;
  padding: 0 0 0 2%; }

.wrap_spisok {
  height: 80%;
  width: 100%;
  position: absolute;
  display: inline-block;
  top: 0;
  left: 0px;
  bottom: 0;
  right: 0;
  margin: auto; }
  @media screen and (max-width: 480px) {
    .wrap_spisok {
      height: 90%; } }

.Block2 {
  width: 100%;
  position: relative;
  height: 600px; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .Block2 {
      height: 420px; } }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .Block2 {
      height: 720px; } }
  @media screen and (max-width: 480px) {
    .Block2 {
      height: 500px; } }

.Block2_1 {
  width: 32.3%;
  height: 240px;
  position: relative;
  display: inline-block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  float: left; }
  .Block2_1:hover {
    width: 33.3%; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .Block2_1 {
      width: 32.3%;
      height: 160px; } }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .Block2_1 {
      width: 47%;
      height: 200px; }
      .Block2_1:hover {
        width: 48%; } }
  @media screen and (max-width: 480px) {
    .Block2_1 {
      width: 47%;
      height: 130px; }
      .Block2_1:hover {
        width: 48%; } }

.Block2_2 {
  width: 32.3%;
  height: 240px;
  position: relative;
  display: inline-block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }
  .Block2_2:hover {
    width: 33.3%; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .Block2_2 {
      width: 32.3%;
      height: 160px; } }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .Block2_2 {
      width: 47%;
      height: 200px; }
      .Block2_2:hover {
        width: 48%; } }
  @media screen and (max-width: 480px) {
    .Block2_2 {
      width: 47%;
      height: 130px; }
      .Block2_2:hover {
        width: 48%; } }

.Block2_3 {
  width: 32.3%;
  height: 240px;
  position: relative;
  display: inline-block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  float: right; }
  .Block2_3:hover {
    width: 33.3%; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .Block2_3 {
      width: 32.3%;
      height: 160px; } }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .Block2_3 {
      width: 47%;
      height: 200px; }
      .Block2_3:hover {
        width: 48%; } }
  @media screen and (max-width: 480px) {
    .Block2_3 {
      width: 47%;
      height: 130px; }
      .Block2_3:hover {
        width: 48%; } }

.img_block2_1 {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  margin: auto;
  height: auto;
  width: 100%; }

.Block3 {
  width: 100%;
  position: relative;
  height: 250px; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .Block3 {
      height: 500px; } }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .Block3 {
      height: 500px; } }
  @media screen and (max-width: 480px) {
    .Block3 {
      height: 500px; } }

.block3_1 {
  width: 19.5%;
  height: 200px;
  position: relative;
  display: inline-block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  float: left;
  vertical-align: top; }
  .block3_1:hover {
    top: 5px; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .block3_1 {
      width: 48%;
      height: 140px; } }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .block3_1 {
      width: 48%;
      height: 140px; } }
  @media screen and (max-width: 480px) {
    .block3_1 {
      width: 48%;
      height: 140px; } }

.block3_2 {
  width: 19.5%;
  height: 200px;
  position: relative;
  display: inline-block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  vertical-align: top; }
  .block3_2:hover {
    top: 5px; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .block3_2 {
      width: 48%;
      height: 140px; } }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .block3_2 {
      width: 48%;
      height: 140px; } }
  @media screen and (max-width: 480px) {
    .block3_2 {
      width: 48%;
      height: 140px; } }

.block3_3 {
  width: 19.5%;
  height: 200px;
  position: relative;
  display: inline-block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  float: right;
  vertical-align: top; }
  .block3_3:hover {
    top: 5px; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .block3_3 {
      width: 98%;
      height: 140px;
      margin: 3% 0 0 0; } }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .block3_3 {
      width: 100%;
      height: 140px;
      margin: 8% 0 0 0; } }
  @media screen and (max-width: 480px) {
    .block3_3 {
      width: 100%;
      height: 140px;
      margin: 15% 0 0 0; } }

.fa.fa-star-o {
  color: #ff9000;
  font-size: 2rem; }
  .fa.fa-star-o:hover {
    font-size: 2.375rem; }

.star1 {
  margin: 35% 0 0% 0;
  text-align: center; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .star1 {
      margin: 0% 0 0% 0; } }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .star1 {
      margin: 0% 0 0% 0; } }
  @media screen and (max-width: 480px) {
    .star1 {
      margin: 0% 0 0% 0; } }

.star2 {
  margin: 15% 0 0% 0;
  text-align: center; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .star2 {
      margin: 0% 0 0% 0; } }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .star2 {
      margin: 0% 0 0% 0; } }
  @media screen and (max-width: 480px) {
    .star2 {
      margin: 0% 0 0% 0; } }

.star3 {
  margin: 0% 0 0% 0;
  text-align: center; }

.zag_star1 {
  width: 95%;
  height: 10px;
  position: relative;
  display: inline-block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  font-family: Firasansregular, sans-serif;
  line-height: 1.2;
  font-size: 0.875rem;
  margin: 10% 0 0 0;
  font-weight: bold;
  color: #02046c; }
  .zag_star1:hover {
    color: #ffa800; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .zag_star1 {
      margin: 2% 0 0 0; } }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .zag_star1 {
      margin: 4% 0 0% 0; } }

.zag_star2 {
  width: 90%;
  height: 10px;
  position: relative;
  display: inline-block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  font-family: Firasansregular, sans-serif;
  line-height: 1.2;
  font-size: 0.875rem;
  margin: 0; }

.line_star {
  width: 50%;
  text-align: center; }

.Block4 {
  width: 100%;
  position: relative;
  height: 460px; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .Block4 {
      height: 670px; } }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .Block4 {
      height: 600px; } }
  @media screen and (max-width: 480px) {
    .Block4 {
      height: 600px; } }

.zag2 {
  color: #ff9000;
  font-family: Firasansbold, sans-serif;
  text-align: center;
  font-size: 1.25rem;
  text-transform: uppercase;
  padding: 0 0 0 0;
  font-weight: bold;
  margin: 30px 0 0px 0; }
  .zag2 span {
    color: #078f17; }

#slider-container {
  position: relative;
  height: 400px;
  width: 600px;
  margin: 0px auto;
  overflow: hidden; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    #slider-container {
      height: 38rem;
      width: 260px; } }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    #slider-container {
      height: 35rem;
      width: 300px; } }
  @media screen and (max-width: 480px) {
    #slider-container {
      height: 38rem;
      width: 210px; } }

.slides {
  height: 100%;
  position: relative; }
  .slides li {
    display: table;
    float: left;
    height: 100%; }
    .slides li div {
      display: table-cell;
      text-align: center; }

.button_line {
  position: absolute;
  height: 60px;
  width: 600px;
  margin: 0px auto 20px auto;
  left: 0;
  bottom: 0;
  right: 0; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .button_line {
      width: 260px; } }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .button_line {
      width: 300px; } }
  @media screen and (max-width: 480px) {
    .button_line {
      width: 210px; } }

.buttons {
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 0;
  margin: 0 auto;
  text-align: center; }
  .buttons li {
    display: inline-block;
    margin: 10px;
    background-color: #e67e22;
    color: #fff;
    padding: 5px 50px;
    border-radius: 5px;
    cursor: pointer; }
    .buttons li:hover {
      background-color: #bf6516; }
    @media screen and (min-width: 768px) and (max-width: 992px) {
      .buttons li {
        padding: 5px 35px; } }
    @media screen and (max-width: 480px) {
      .buttons li {
        padding: 5px 35px; } }

.sl_item {
  height: 65%;
  width: 600px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: relative;
  margin: 0 auto; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .sl_item {
      width: 260px; } }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .sl_item {
      width: 300px; } }
  @media screen and (max-width: 480px) {
    .sl_item {
      width: 210px; } }

.name_otziv {
  padding: 5% 0 2% 0;
  margin: 0% auto;
  color: #000;
  font-family: open-sans-light, sans-serif;
  font-size: 1.25rem;
  line-height: 1.5;
  text-align: center;
  width: 600px;
  height: auto; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .name_otziv {
      width: 260px; } }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .name_otziv {
      width: 300px; } }
  @media screen and (max-width: 480px) {
    .name_otziv {
      width: 210px; } }

.zvezdi_otziv {
  padding: 0% 0% 2% 0%;
  margin: 0% auto;
  color: #f9cb74;
  font-size: 1.25rem;
  line-height: 1.5;
  text-align: center;
  width: 600px;
  height: auto; }
  .zvezdi_otziv i {
    margin: 0 5px; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .zvezdi_otziv {
      width: 260px; } }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .zvezdi_otziv {
      width: 300px; } }
  @media screen and (max-width: 480px) {
    .zvezdi_otziv {
      width: 210px; } }

.otziv {
  padding: 3% 0 0 0;
  margin: 0% auto;
  color: #000;
  font-family: open-sans-light, sans-serif;
  font-size: 0.875rem;
  line-height: 1.5;
  text-align: center;
  width: 600px;
  height: auto; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .otziv {
      width: 260px; } }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .otziv {
      width: 300px; } }
  @media screen and (max-width: 480px) {
    .otziv {
      width: 210px; } }

.Block5 {
  width: 100%;
  position: relative;
  height: 260px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto; }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .Block5 {
      height: 300px; } }

.wrap_img_zakaz {
  width: 80%;
  height: 87%;
  position: absolute;
  display: inline-block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .wrap_img_zakaz {
      width: 100%; } }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .wrap_img_zakaz {
      width: 100%; } }
  @media screen and (max-width: 480px) {
    .wrap_img_zakaz {
      width: 100%; } }

.img_zakaz {
  width: auto;
  height: 100%;
  position: absolute;
  margin: auto 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: inline-block; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .img_zakaz {
      height: 85%; } }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .img_zakaz {
      display: none; } }
  @media screen and (max-width: 480px) {
    .img_zakaz {
      display: none; } }

.form_zakaz {
  width: 45%;
  height: 144%;
  background-color: #f9cb74;
  position: absolute;
  display: inline-block;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 0;
  margin: auto 0;
  border-radius: 10px; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .form_zakaz {
      width: 49%; } }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .form_zakaz {
      width: 100%;
      left: 0; } }
  @media screen and (max-width: 480px) {
    .form_zakaz {
      width: 100%;
      left: 0; } }

.vis_zakaz {
  padding: 0% 0 0 0;
  margin: 2% auto 0 auto;
  color: #ff6c00;
  font-family: Firasansregular, sans-serif;
  font-size: 1.375rem;
  line-height: 1;
  text-align: justify;
  width: 80%;
  height: auto;
  font-weight: bold; }

.vis_zakaz1 {
  padding: 0% 0 0 0;
  margin: 2% auto 0 auto;
  color: #02046c;
  font-family: Firasansregular, sans-serif;
  font-size: 0.875rem;
  line-height: 1;
  text-align: justify;
  width: 80%;
  height: auto; }

.form {
  height: auto;
  width: 100%;
  position: absolute;
  margin: 0 auto; }

.inp_name {
  text-align: center;
  margin: 2% auto 2% auto; }

.inp__phon {
  text-align: center;
  margin: 0 auto 2% auto; }

.inp__email {
  text-align: center;
  margin: 0 auto 2% auto; }

.block__textar {
  text-align: center;
  margin: 0 auto 2% auto; }

.name {
  font-family: open-sans-light, sans-serif;
  color: #000;
  font-size: 0.875rem;
  padding: 0 0 0px 10px;
  width: 80%;
  outline: none;
  height: 1.875rem;
  border-radius: 2px;
  background-color: transparent;
  border: 1px solid #8e8e8e; }

.textar {
  padding: 10px 0 0 10px;
  width: 80%;
  height: 5rem;
  font-family: open-sans-light, sans-serif;
  color: #000;
  font-size: 0.875rem;
  resize: none;
  outline: none;
  border-radius: 2px;
  background-color: transparent;
  border: 1px solid #8e8e8e; }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .textar {
      height: 6.25rem; } }
  @media screen and (max-width: 480px) {
    .textar {
      height: 7.5rem; } }

.btn2 {
  width: 11.25rem;
  height: 2.25rem;
  border-radius: 4px;
  color: #fff;
  font-family: open-sans-light, sans-serif;
  display: inline-block;
  background: #ffa800;
  outline: none;
  text-decoration: none;
  border: none;
  position: absolute;
  margin: 0 auto;
  text-align: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }
  .btn2:hover {
    color: #000;
    cursor: pointer; }

.Block6 {
  width: 100%;
  position: relative;
  height: 400px;
  margin: 10% 0 0 0; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .Block6 {
      height: 500px;
      margin: 20% 0 0 0; } }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .Block6 {
      height: 530px;
      margin: 20% 0 0 0; } }
  @media screen and (max-width: 480px) {
    .Block6 {
      height: 530px;
      margin: 30% 0 0 0; } }

.wrap_img_blog {
  width: 40%;
  height: 30%;
  position: relative;
  display: inline-block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto 2% auto 0;
  float: left; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .wrap_img_blog {
      width: 100%;
      height: 15%;
      float: none; } }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .wrap_img_blog {
      width: 100%;
      height: 15%;
      float: none; } }
  @media screen and (max-width: 480px) {
    .wrap_img_blog {
      width: 100%;
      height: 15%;
      float: none; } }

.img_blog {
  width: auto;
  height: 100%;
  position: absolute;
  margin: auto 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: inline-block; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .img_blog {
      margin: auto; } }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .img_blog {
      margin: auto; } }

.blog_txt {
  width: 58%;
  height: 60%;
  position: relative;
  display: inline-block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  float: right; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .blog_txt {
      width: 100%;
      height: 20%;
      float: none;
      margin: 7% auto 0 auto; } }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .blog_txt {
      width: 100%;
      height: 20%;
      float: none;
      margin: 7% auto 0 auto; } }
  @media screen and (max-width: 480px) {
    .blog_txt {
      width: 100%;
      height: 20%;
      float: none;
      margin: 7% auto 0 auto; } }

.zag_blog_item {
  padding: 0% 0 0 0;
  margin: 0% auto 0 auto;
  color: #0f9929;
  font-family: Firasansregular, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  width: 80%;
  height: auto;
  font-weight: bold; }

.txt_blog_item {
  padding: 0% 0 0 0;
  margin: 5% auto 5% auto;
  color: #000;
  font-family: Firasansregular, sans-serif;
  font-size: 0.875rem;
  line-height: 1.3;
  text-align: justify;
  width: 90%;
  height: auto; }

.wrap_btn3 {
  width: 80%;
  height: 2.5rem;
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 10% auto 0 auto;
  text-align: center; }

.btn3 {
  width: 8.125rem;
  height: 2.25rem;
  border-radius: 4px;
  color: #fff;
  font-family: open-sans-light, sans-serif;
  display: inline-block;
  background: #ffa800;
  outline: none;
  text-decoration: none;
  border: none;
  position: relative;
  margin: 0;
  text-align: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }
  .btn3:hover {
    color: #000;
    cursor: pointer; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .btn3 {
      width: 11.25rem; } }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .btn3 {
      margin: auto;
      display: block;
      width: 11.25rem; } }
  @media screen and (max-width: 480px) {
    .btn3 {
      margin: auto;
      display: block;
      width: 11.25rem; } }

.btn4 {
  width: 8.125rem;
  height: 2.25rem;
  border-radius: 4px;
  color: #fff;
  font-family: open-sans-light, sans-serif;
  display: inline-block;
  background: #00abaa;
  outline: none;
  text-decoration: none;
  border: none;
  position: relative;
  margin: 0 10% 0 0;
  text-align: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }
  .btn4:hover {
    color: #000;
    cursor: pointer; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .btn4 {
      width: 11.25rem; } }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .btn4 {
      margin: 0 auto 5% auto;
      display: block;
      width: 11.25rem; } }
  @media screen and (max-width: 480px) {
    .btn4 {
      margin: 0 auto 5% auto;
      display: block;
      width: 11.25rem; } }

.line_blog {
  margin: 40% auto 0 auto;
  width: 80%;
  display: none; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .line_blog {
      margin: 10% auto 0 auto;
      display: block; } }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .line_blog {
      margin: 17% auto 0 auto;
      display: block; } }
  @media screen and (max-width: 480px) {
    .line_blog {
      margin: 17% auto 0 auto;
      display: block; } }

.Block7 {
  width: 100%;
  position: relative;
  height: 300px;
  margin: 0; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .Block7 {
      height: 500px; } }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .Block7 {
      height: 500px; } }
  @media screen and (max-width: 480px) {
    .Block7 {
      height: 480px; } }

.Block8 {
  width: 100%;
  position: relative;
  height: 250px; }

.partners {
  width: 98%;
  height: 30%;
  position: relative;
  display: inline-block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto 2% auto 0;
  text-align: center; }

.img_partners {
  width: auto;
  height: 100%;
  position: relative;
  margin: auto 3%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: inline-block; }
  @media screen and (max-width: 480px) {
    .img_partners {
      height: 70%;
      margin: 5% auto 0 auto; } }

.footer__ff {
  width: 100%;
  top: 0px;
  height: 15rem;
  margin-top: -15rem;
  position: relative;
  background-color: #1f1f1e;
  display: block; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .footer__ff {
      height: 22.5rem;
      margin-top: -22.5rem; } }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .footer__ff {
      height: 45rem;
      margin-top: -45rem; } }
  @media screen and (max-width: 480px) {
    .footer__ff {
      height: 45rem;
      margin-top: -15.625rem; } }

.footerr {
  width: 100%;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  height: 96%;
  position: absolute;
  display: block;
  align-items: flex-start; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .footerr {
      display: block; } }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .footerr {
      display: block; } }
  @media screen and (max-width: 480px) {
    .footerr {
      display: block; } }

.footer_1 {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: relative;
  display: inline-block;
  margin: auto;
  height: 100%;
  width: 10%;
  float: left; }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .footer_1 {
      height: 50px;
      width: 100%;
      display: block;
      margin: 5% 0 0 0; } }
  @media screen and (max-width: 480px) {
    .footer_1 {
      height: 50px;
      width: 100%;
      display: block;
      margin: 5% 0 0 0; } }

.footer_2 {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: relative;
  display: inline-block;
  margin: 0;
  height: 100%;
  width: 30%; }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .footer_2 {
      height: 300px;
      width: 100%;
      display: block;
      text-align: center; } }
  @media screen and (max-width: 480px) {
    .footer_2 {
      height: 250px;
      width: 100%;
      display: block;
      text-align: center; } }

.footer_3 {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: relative;
  display: inline-block;
  margin: 0;
  height: 100%;
  width: 30%;
  vertical-align: top; }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .footer_3 {
      height: 135px;
      width: 55%;
      display: block;
      margin: 0 auto; } }
  @media screen and (max-width: 480px) {
    .footer_3 {
      height: 130px;
      width: 55%;
      display: block;
      margin: 0 auto; } }

.footer_4 {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: relative;
  display: inline-block;
  margin: auto;
  height: 100%;
  width: 27%;
  float: right; }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .footer_4 {
      height: 180px;
      width: 100%;
      display: block; } }
  @media screen and (max-width: 480px) {
    .footer_4 {
      height: 180px;
      width: 100%;
      display: block; } }

.f_1_text {
  padding: 0% 0 0% 0;
  margin: auto;
  color: #f9cb74;
  font-family: MyriadPro-Regular, sans-serif;
  font-size: 1.875rem;
  line-height: 1.1;
  width: 80%;
  height: auto;
  text-align: center; }

.f_1_text1 {
  padding: 0% 0 0% 0;
  margin: auto;
  color: #b6b6b5;
  font-family: MyriadPro-Regular, sans-serif;
  font-size: 0.875rem;
  line-height: 1.1;
  width: 80%;
  height: auto;
  text-align: center; }

.menu_f {
  color: #6244cb;
  font-family: MyriadPro-Regular, sans-serif;
  font-size: 1rem;
  line-height: 1.1; }
  .menu_f:hover {
    color: #f9cb74; }

.menu_fff {
  padding: 0 0 2% 0; }

.wrap_menu_ff {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: relative;
  margin: 0 auto;
  height: 100%;
  width: 80%;
  justify-content: center; }

.menu_ff {
  margin: 3% 0 0 0; }

.footer_3_1 {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: relative;
  margin: 0;
  height: 20%;
  width: 100%;
  display: inline-block; }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .footer_3_1 {
      height: 24%;
      width: 100%; } }
  @media screen and (max-width: 480px) {
    .footer_3_1 {
      height: 24%;
      width: 100%; } }

.f_3_1 {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: relative;
  margin: 0 0;
  height: 100%;
  width: 9%;
  color: #f9cb74;
  text-align: center;
  display: inline-block; }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .f_3_1 {
      width: 6%; } }
  @media screen and (max-width: 480px) {
    .f_3_1 {
      width: 6%; } }

.f_3_2 {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: relative;
  margin: 0 0% 0 0%;
  height: 100%;
  width: 89%;
  display: inline-block; }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .f_3_2 {
      margin: auto 0% auto 0%; } }
  @media screen and (max-width: 480px) {
    .f_3_2 {
      margin: auto 0% auto 0%; } }

.f_3_2_text {
  color: #b6b6b5;
  font-family: MyriadPro-Regular, sans-serif;
  font-size: 0.875rem;
  line-height: 1.1; }

.wrap_img_f {
  height: 11.25rem;
  width: 100%;
  position: relative;
  display: inline-block; }

.img_f {
  height: auto;
  width: 90%;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: inline-block; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .img_f {
      width: 90%; } }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .img_f {
      width: 65%;
      display: inline-block; } }
  @media screen and (max-width: 480px) {
    .img_f {
      display: inline-block;
      width: 90%; } }
