@font-face {
    font-family: roboto-regular;
    src: url(../fonts/roboto-regular.ttf);}

@font-face {
    font-family: roboto-medium;
    src: url(../fonts/roboto-medium.ttf);}

@font-face {
    font-family: fontawesome-webfont;
    src: url(../fonts/fontawesome-webfont.ttf);}

@font-face {
    font-family: Firasanslight;
    src: url(../fonts/Firasanslight.ttf);}

@font-face {
    font-family: Firasansbold;
    src: url(../fonts/Firasansbold.ttf);}

@font-face {
    font-family: Firasansregular;
    src: url(../fonts/Firasansregular.ttf);}

@font-face {
    font-family: SWSimp;
    src: url(../fonts/SWSimp.ttf);}
    
@font-face {
    font-family: open-sans-light;
    src: url(../fonts/open-sans-light.ttf);}


@font-face {
    font-family: MyriadPro-Regular;
    src: url(../fonts/MyriadPro-Regular.otf);}


////////Preloader
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
//  background-color: #ECA839;
  z-index: 80;
  position: absolute;
}




#status {
  width: auto;
  height: rem(650px);
  position: absolute;
  background: url(../img/Loader.gif) no-repeat center center;
  background-size: cover;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 81;
}

.wrap__index{
  display: none;
}


.loader-bar{
  width: 1%;
  height: 2px;
  background: #ECA839;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 82;
}

////////////////////////
//preloader3

.loader {
  background: none repeat scroll 0 0 #000;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 9999; }

  .loader .loader_inner {
    width: auto;
    height: rem(650px);
    background-image: url("../img/Loader.gif");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #000;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
//    height: 60px;
//    width: 60px;
//    margin-top: -30px;
//    margin-left: -30px;
//    left: 50%;
//    top: 50%;
     }

///////////////////////////////////


.cont__ff{
  height: rem(4160px);
  width: 100%;
    @include laptop{
      height: rem(5600px);
      }
    @include tablet{
      height: rem(6560px);
      }
    @include phone{
      height: rem(5920px);
      }
}

.header{
  height: rem(380px);
  width: 100%;
}

.head_fon{
//  transform: translate3d(0,0,0);
//  background: #faecdc url(../img/sand.jpg) bottom left;
  background-color: #3d107b;
  background-repeat: repeat-x;
//  background-size: rem(3056px) auto;
  height: rem(380px);
  width: 100%;
//  width: rem(6112px);
//  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
//  animation: bg 120s linear infinite;
}

@keyframes bg{
  0% {transform: translate3d(0,0,0);}
  100% {transform: translate3d(-3056px,0,0);}
}


.menu_top{
  width: 100%;
  height: rem(20px);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: relative;
  display: block;
  margin: 0 auto;
  padding: 1% 0 0 0;
    @include laptop{
      
      }
    @include tablet{
      
      }
    @include phone{
      display: none;
      }
}

.menu_t{
  width: 100%;
  height: rem(20px);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: relative;
  display: block;
  margin: auto;
  padding: 0;
  text-align: center;
}

.menu_tt{
  padding: 5% 2% 0 1%;
  display: inline;
  list-style-type: none;
}

.menu_ttt{
  text-decoration: none;
  color: #fff;
  font-family: MyriadPro-Regular, sans-serif;
  font-size: rem(14px);
  line-height: 1.1;
    &:hover{
    color: #f9cb74;
    }
}



.head_text{
  width: 60%;
  height: 80%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: relative;
  display: inline-block;
  float: left;
    @include laptop{
      
      }
    @include tablet{
      width: 100%;
      }
    @include phone{
      width: 100%;
      }
}

.head_pic{
  width: 40%;
  height: 80%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: relative;
  display: inline-block;
  float: right;
    @include laptop{
      
      }
    @include tablet{
      display: none;
      }
    @include phone{
      display: none;
      }
}

.wrap_head_text{
  position: absolute;
  display: inline-block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 7% auto 0 auto;
  height: rem(300px);
  width: 100%;
}

.dobr{
  color: #ffffff;
  font-family: Firasanslight, sans-serif;
  font-size: rem(16px);
  text-align: center;
  margin: 0 0 5px 0;
}

.text_proekt_comp{
  color: #f9cb74;
  font-family: Firasansregular, sans-serif;
  font-size: rem(40px);
  text-align: center;
  margin: 0;
}


.text_proekt_comp:before,
.text_proekt_comp:after{
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: rem(60px);
  height: 3px;
  margin: 0 10px;
  border-top: 1px solid #f9cb74;
}

.wrap_line{
  width: 65%;
  height: 5px;
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0 auto;
}

.line{
  border-bottom: 1px solid #fff;
  width: 100%;
  height: 5px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0 auto;
}

.head_text1{
  color: #ffffff;
  font-family: open-sans-light, sans-serif;
  font-size: rem(13px);
  text-align: center;
  margin: 14px auto 40px auto;
  width: 55%;
  height: auto;
  line-height: 18px;
    @include laptop{
      }
    @include tablet{
      width: 80%;
      margin: 14px auto 10px auto;
      }
    @include phone{
      width: 80%;
      margin: 14px auto 10px auto;
      }
}

.wrap_btn1{
  width: 70%;
  height: rem(40px);
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 5px auto 0 auto;
    @include laptop{
      }
    @include tablet{
      margin: 20px auto 0 auto;
      }
    @include phone{
      }
}


.btn1{
  width: rem(180px);
  height: rem(40px);
  border-radius: 3px;
  color: #fff;
  font-family: open-sans-light, sans-serif;
  display: inline-block;
  background: #ffa800;
  outline: none;
  text-decoration: none;
  border: none;
  position: absolute;
  margin: 0 auto;
  text-align: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
    &:hover{
    color: #000;
    cursor: pointer;
    }
}

.wrap_img{
  position: absolute;
  display: inline-block;
  top: 0;
  left: -150px;
  bottom: 0;
  right: 0;
  margin: 0% auto 0 auto;
  height: rem(250px);
  width: auto;
    @include laptop{
      margin: 17% auto 0 auto;
      }
    @include tablet{
      }
    @include phone{
      }
}

.img_head{
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  margin: 0 auto;
  height: auto;
  width: 80%;
}

.str_bottom{
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: relative;
  margin: 0 auto;
  height: rem(20px);
  width: 100%;
    i{
    color: #ffa800;
    text-align: center;
    font-size: rem(40px);
    }
}

.wrap_str_bottom{
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: relative;
  margin: 0 auto;
  height: rem(20px);
  width: 100%;
  text-align: center;
}

ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

ul li{
  list-style-type: none;
  vertical-align: middle;
}

////////////////////////////////////////
//Sidebar
.Sidebar{
  width: 30%;
  height: 78%;
  position: relative;
  display: inline-block;
  float: left;
  overflow: hidden;
}

.nav_uslugi{
  width: 90%;
  height: 20px;
  background-color: #f9cb74;
  position: relative;
  display: block;
  margin: 0 auto;
}

.nav_uslugi1{
  width: 90%;
  height: 20px;
  background-color: #f9cb74;
  position: relative;
  display: block;
  margin: 0 auto;
    @include laptop{
      }
    @include tablet{
      }
    @include phone{
      height: 40px;
      }
}


.zag_usl{
  color: #02046c;
  font-family: Firasansregular, sans-serif;
  text-align: center;
  font-size: rem(18px);
  padding: 0 0 0 0;
  margin: 30px 0 0px 0;
    @include laptop{
      font-size: rem(16px);
      }
    @include tablet{
      font-size: rem(16px);
      }
    @include phone{
      font-size: rem(16px);
      }
}

.usl_spisok{
  color: #02046c;
  font-family: Firasanslight, sans-serif;
  font-size: rem(16px);
  margin: 5% 0 0 10%;
}

.usl_item{
  margin: 2% 0;
}



////////////////////////////////////////
//Content_block

.Content_block{
  width: 70%;
  height: 78%;
  position: relative;
  display: inline-block;
  float: right;
}

.Block1{
  width: 100%;
  position: relative;
  height: 710px;
    @include laptop{
      height: 1430px;
      }
    @include tablet{
      height: 1380px;
      }
    @include phone{
      height: 1350px;
      }
}

.zag1{
  color: #02046c;
  font-family: Firasansbold, sans-serif;
  text-align: center;
  font-size: rem(20px);
  text-transform: uppercase;
  padding: 0 0 0 0;
  font-weight: bold;
  margin: 30px 0 30px 0;

    @include laptop{
      }
    @include tablet{
      }
    @include phone{
      font-size: rem(18px);
      margin: 20px 0 20px 0;
      }
}

.zag1:before,
.zag1:after{
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: rem(60px);
  height: 3px;
  margin: 0 10px;
  border-top: 1px solid #02046c;
    @include laptop{
      }
    @include tablet{
      display: none;
      }
    @include phone{
      display: none;
      }
}


.Block1_1{
  width: 48%;
  height: 210px;
  border: 1px solid #02046c;
  display: inline-block;
  position: relative;
  float: left;
  margin: 0 0% 1% 0;
    &:hover{
    border: 1px solid #f9cb74;
    cursor: pointer;
    }

    @include laptop{
      float: none;
      display: block;
      width: 98%;
      margin: 0 0% 3% 0;
      }
    @include tablet{
      float: none;
      display: block;
      width: 98%;
      margin: 0 0% 3% 0;
      }
    @include phone{
      float: none;
      display: block;
      width: 98%;
      margin: 0 0% 3% 0;
      }
}

.Block1_2{
  width: 48%;
  height: 210px;
  border: 1px solid #02046c;
  display: inline-block;
  position: relative;
  float: right;
  margin: 0 1% 1% 0;
    &:hover{
    border: 1px solid #f9cb74;
    cursor: pointer;
    }

    @include laptop{
      float: none;
      display: block;
      width: 98%;
      margin: 0 1% 3% 0;
      }
    @include tablet{
      float: none;
      display: block;
      width: 98%;
      margin: 0 1% 3% 0;
      }
    @include phone{
      float: none;
      display: block;
      width: 98%;
      margin: 0 1% 3% 0;
      }
}

.block1_1_pic{
  width: 45%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: relative;
  display: inline-block;
    @include laptop{
      }
    @include tablet{
      }
    @include phone{
      }
}

.wrap_block1_1_img{
  position: relative;
  display: inline-block;
  top: 0;
  left: 0px;
  bottom: 0;
  right: 0;
  margin: 0;
  height: 90%;
  width: 40%;
  vertical-align: bottom;

}

.img_block1_1{
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  margin: auto;
  height: auto;
  width: 100%;
}

.zag_Block1_1{
  color: #02046c;
  font-family: Firasansbold, sans-serif;
  text-align: center;
  font-size: rem(14px);
  padding: 1% 0 0 0;
  font-weight: bold;
  margin: 0;
  }

.wrap_block1_1_txt{
  position: relative;
  display: inline-block;
  top: 0;
  left: 0px;
  bottom: 0;
  right: 0;
  margin: 0;
  height: 90%;
  width: 60%;
  float: right;
    @include laptop{
      }
    @include tablet{
      }
    @include phone{
      height: 90%;
      }
}

.spisok_b1_1{
  margin: auto;
  font-family: open-sans-light, sans-serif;
  line-height: 1.5;
  width: 100%;
  font-size: rem(14px);
  padding: 0 0 0 2%;
}

.wrap_spisok{
  height: 80%;
  width: 100%;
  position: absolute;
  display: inline-block;
  top: 0;
  left: 0px;
  bottom: 0;
  right: 0;
  margin: auto;
    @include laptop{
      }
    @include tablet{
      }
    @include phone{
      height: 90%;
      }
}

//////////////////////////
//Block2

.Block2{
  width: 100%;
  position: relative;
  height: 600px;
    @include laptop{
      height: 420px;
      }
    @include tablet{
      height: 720px;
      }
    @include phone{
      height: 500px;
      }
}

.Block2_1{
  width: 32.3%;
  height: 240px;
  position: relative;
  display: inline-block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  float: left;
    &:hover{
      width: 33.3%;
    }

    @include laptop{
      width: 32.3%;
      height: 160px;
      }
    @include tablet{
      width: 47%;
      height: 200px;
        &:hover{
          width: 48%;
        }
      }
    @include phone{
      width: 47%;
      height: 130px;
        &:hover{
          width: 48%;
        }
      }
}

.Block2_2{
  width: 32.3%;
  height: 240px;
  position: relative;
  display: inline-block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
    &:hover{
      width: 33.3%;
    }

    @include laptop{
      width: 32.3%;
      height: 160px;
      }
    @include tablet{
      width: 47%;
      height: 200px;
        &:hover{
          width: 48%;
        }
      }
    @include phone{
      width: 47%;
      height: 130px;
        &:hover{
          width: 48%;
        }
      }
}

.Block2_3{
  width: 32.3%;
  height: 240px;
  position: relative;
  display: inline-block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  float: right;
    &:hover{
      width: 33.3%;
    }

    @include laptop{
      width: 32.3%;
      height: 160px;
      }
    @include tablet{
      width: 47%;
      height: 200px;
        &:hover{
          width: 48%;
        }
      }
    @include phone{
      width: 47%;
      height: 130px;
        &:hover{
          width: 48%;
        }
      }
}


.img_block2_1{
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  margin: auto;
  height: auto;
  width: 100%;

}


//////////////////////////
//Block3

.Block3{
  width: 100%;
  position: relative;
  height: 250px;
    @include laptop{
      height: 500px;
      }
    @include tablet{
      height: 500px;
      }
    @include phone{
      height: 500px;
      }
}

.block3_1{
  width: 19.5%;
  height: 200px;
  position: relative;
  display: inline-block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  float: left;
  vertical-align: top;
    &:hover{
      top: 5px;
    }
    @include laptop{
      width: 48%;
      height: 140px;
      }
    @include tablet{
      width: 48%;
      height: 140px;
      }
    @include phone{
      width: 48%;
      height: 140px;
      }
}

.block3_2{
  width: 19.5%;
  height: 200px;
  position: relative;
  display: inline-block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  vertical-align: top;
    &:hover{
      top: 5px;
    }
    @include laptop{
      width: 48%;
      height: 140px;
      }
    @include tablet{
      width: 48%;
      height: 140px;
      }
    @include phone{
      width: 48%;
      height: 140px;
      }
}

.block3_3{
  width: 19.5%;
  height: 200px;
  position: relative;
  display: inline-block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  float: right;
  vertical-align: top;
    &:hover{
      top: 5px;
    }
    @include laptop{
      width: 98%;
      height: 140px;
      margin: 3% 0 0 0;
      }
    @include tablet{
      width: 100%;
      height: 140px;
      margin: 8% 0 0 0;
      }
    @include phone{
      width: 100%;
      height: 140px;
      margin: 15% 0 0 0;
      }
}

.fa.fa-star-o{
  color: #ff9000;
  font-size: rem(32px);
    &:hover{
      font-size: rem(38px);
    }
}

.star1{
  margin: 35% 0 0% 0;
  text-align: center;
    @include laptop{
      margin: 0% 0 0% 0;
      }
    @include tablet{
      margin: 0% 0 0% 0;
      }
    @include phone{
      margin: 0% 0 0% 0;
      }
}

.star2{
  margin: 15% 0 0% 0;
  text-align: center;
    @include laptop{
      margin: 0% 0 0% 0;
      }
    @include tablet{
      margin: 0% 0 0% 0;
      }
    @include phone{
      margin: 0% 0 0% 0;
      }
}

.star3{
  margin: 0% 0 0% 0;
  text-align: center;
}

.zag_star1{
  width: 95%;
  height: 10px;
  position: relative;
  display: inline-block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  font-family: Firasansregular, sans-serif;
  line-height: 1.2;
  font-size: rem(14px);
  margin: 10% 0 0 0;
  font-weight: bold;
  color: #02046c;
    &:hover{
      color: #ffa800;
    }
    @include laptop{
      margin: 2% 0 0 0;
      }
    @include tablet{
      margin: 4% 0 0% 0;
      }
    @include phone{
      
      }
}

.zag_star2{
  width: 90%;
  height: 10px;
  position: relative;
  display: inline-block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  font-family: Firasansregular, sans-serif;
  line-height: 1.2;
  font-size: rem(14px);
  margin: 0;
}

.line_star{
  width: 50%;
  text-align: center;
}


////////////////////////////////////////
//block4

.Block4{
  width: 100%;
  position: relative;
  height: 460px;
    @include laptop{
      height: 670px;
      }
    @include tablet{
      height: 600px;
      }
    @include phone{
      height: 600px;
      }
}

.zag2{
  color: #ff9000;
  font-family: Firasansbold, sans-serif;
  text-align: center;
  font-size: rem(20px);
  text-transform: uppercase;
  padding: 0 0 0 0;
  font-weight: bold;
  margin: 30px 0 0px 0;
    span{
    color: #078f17;
    }
}


#slider-container {
  position: relative;
  height: 400px;
  width: 600px;
  margin: 0px auto;
  overflow:hidden;
    @include laptop{
      height: rem(608px);
      width: 260px;
      }
    @include tablet{
      height: rem(560px);
      width: 300px;
      }
    @include phone{
      height: rem(608px);
      width: 210px;
      }
}

.slides {
  height: 100%;
  position: relative;
  li {
    display: table;
    float:left;
    height: 100%;
    div {
      display: table-cell;
//      vertical-align: middle;
      text-align:center;
    }
  }
}

.button_line{
  position: absolute;
  height: 60px;
  width: 600px;
  margin: 0px auto 20px auto;
  left: 0;
  bottom: 0;
  right: 0;
    @include laptop{
      width: 260px;
      }
    @include tablet{
      width: 300px;
      }
    @include phone{
      width: 210px;
      }
}



.buttons {
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 0;
  margin: 0 auto;
  text-align: center;
  li {
    display:inline-block;
    margin:10px;
    background-color: #e67e22;
    color:#fff;
    padding: 5px 50px;
    border-radius:5px;
    cursor: pointer;
    &:hover {
      background-color: darken(#e67e22,10%);
     }
    @include laptop{
      padding: 5px 35px;
      }
    @include tablet{
      
      }
    @include phone{
      padding: 5px 35px;
      }
  }
}


.sl_item{
  height: 65%;
  width: 600px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: relative;
  margin: 0 auto;
//  vertical-align: middle;
    @include laptop{
      width: 260px;
      }
    @include tablet{
      width: 300px;
      }
    @include phone{
      width: 210px;
      }
}




.name_otziv{
  padding: 5% 0 2% 0;
  margin: 0% auto;
  color: #000;
  font-family: open-sans-light, sans-serif;
  font-size: rem(20px);
  line-height: 1.5;
  text-align: center;
  width: 600px;
  height: auto;
    @include laptop{
      width: 260px;
      }
    @include tablet{
      width: 300px;
      }
    @include phone{
      width: 210px;
      }
}

.zvezdi_otziv{
  padding: 0% 0% 2% 0%;
  margin: 0% auto;
  color: #f9cb74;
  font-size: rem(20px);
  line-height: 1.5;
  text-align: center;
  width: 600px;
  height: auto;
    i{
    margin: 0 5px;
    }
    @include laptop{
      width: 260px;
      }
    @include tablet{
      width: 300px;
      }
    @include phone{
      width: 210px;
      }
}


.otziv{
  padding: 3% 0 0 0;
  margin: 0% auto;
  color: #000;
  font-family: open-sans-light, sans-serif;
  font-size: rem(14px);
  line-height: 1.5;
  text-align: center;
  width: 600px;
  height: auto;
    @include laptop{
      width: 260px;
      }
    @include tablet{
      width: 300px;
      }
    @include phone{
      width: 210px;
      }
}

////////////////////////////////////////
//block5

.Block5{
  width: 100%;
  position: relative;
  height: 260px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
    @include laptop{
      }
    @include tablet{
      height: 300px;
      }
    @include phone{
      
      }
}

.wrap_img_zakaz{
  width: 80%;
  height: 87%;
  position: absolute;
  display: inline-block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
    @include laptop{
      width: 100%;
      }
    @include tablet{
      width: 100%;
      }
    @include phone{
      width: 100%;
      }
}

.img_zakaz{
  width: auto;
  height: 100%;
  position: absolute;
  margin: auto 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: inline-block;
    @include laptop{
      height: 85%;
      }
    @include tablet{
      display: none;
      }
    @include phone{
      display: none;
      }
}

.form_zakaz{
  width: 45%;
  height: 144%;
  background-color: #f9cb74;
  position: absolute;
  display: inline-block;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 0;
  margin: auto 0;
  border-radius: 10px;
    @include laptop{
      width: 49%;
      }
    @include tablet{
      width: 100%;
      left: 0;
      }
    @include phone{
      width: 100%;
      left: 0;
      }
}



.vis_zakaz{
  padding: 0% 0 0 0;
  margin: 2% auto 0 auto;
  color: #ff6c00;
  font-family: Firasansregular, sans-serif;
  font-size: rem(22px);
  line-height: 1;
  text-align: justify;
  width: 80%;
  height: auto;
  font-weight: bold;
}

.vis_zakaz1{
  padding: 0% 0 0 0;
  margin: 2% auto 0 auto;
  color: #02046c;
  font-family: Firasansregular, sans-serif;
  font-size: rem(14px);
  line-height: 1;
  text-align: justify;
  width: 80%;
  height: auto;
  
}

.form{
  height: auto;
  width: 100%;
  position: absolute;
  margin: 0 auto;
}

.inp_name{
  text-align: center;
  margin: 2% auto 2% auto;
}

.inp__phon{
  text-align: center;
  margin: 0 auto 2% auto;
}

.inp__email{
  text-align: center;
  margin: 0 auto 2% auto;
}

.block__textar{
  text-align: center;
  margin: 0 auto 2% auto;
}

.name{
  font-family: open-sans-light, sans-serif;
    color: #000;
    font-size: rem(14px);
    padding: 0 0 0px 10px;
    width: 80%;
    outline: none;
    height: rem(30px);
    border-radius: 2px;
    background-color: transparent;
    border: 1px solid #8e8e8e;
}

.textar{
  padding: 10px 0 0 10px;
  width: 80%;
  height: rem(80px);
  font-family: open-sans-light, sans-serif;
  color: #000;
  font-size: rem(14px);
  resize: none;
  outline: none;
  border-radius: 2px;
  background-color: transparent;
  border: 1px solid #8e8e8e;
    @include laptop{
      
      }
    @include tablet{
      height: rem(100px);
      }
    @include phone{
      height: rem(120px);
      }
}


.btn2{
  width: rem(180px);
  height: rem(36px);
  border-radius: 4px;
  color: #fff;
  font-family: open-sans-light, sans-serif;
  display: inline-block;
  background: #ffa800;
  outline: none;
  text-decoration: none;
  border: none;
  position: absolute;
  margin: 0 auto;
  text-align: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
    &:hover{
    color: #000;
    cursor: pointer;
    }
}


//////////////////////////
//Block6

.Block6{
  width: 100%;
  position: relative;
  height: 400px;
  margin: 10% 0 0 0;
    @include laptop{
      height: 500px;
      margin: 20% 0 0 0;
      }
    @include tablet{
      height: 530px;
      margin: 20% 0 0 0;
      }
    @include phone{
      height: 530px;
      margin: 30% 0 0 0;
      }
}

.wrap_img_blog{
  width: 40%;
  height: 30%;
  position: relative;
  display: inline-block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto 2% auto 0;
  float: left;
    @include laptop{
      width: 100%;
      height: 15%;
      float: none;
      }
    @include tablet{
      width: 100%;
      height: 15%;
      float: none;
      }
    @include phone{
      width: 100%;
      height: 15%;
      float: none;
      }
}

.img_blog{
  width: auto;
  height: 100%;
  position: absolute;
  margin: auto 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: inline-block;
    @include laptop{
      margin: auto;
      }
    @include tablet{
      margin: auto;
      }
    @include phone{

      }
}

.blog_txt{
  width: 58%;
  height: 60%;
  position: relative;
  display: inline-block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  float: right;
    @include laptop{
      width: 100%;
      height: 20%;
      float: none;
      margin: 7% auto 0 auto;
      }
    @include tablet{
      width: 100%;
      height: 20%;
      float: none;
      margin: 7% auto 0 auto;
      }
    @include phone{
      width: 100%;
      height: 20%;
      float: none;
      margin: 7% auto 0 auto;
      }
}


.zag_blog_item{
  padding: 0% 0 0 0;
  margin: 0% auto 0 auto;
  color: #0f9929;
  font-family: Firasansregular, sans-serif;
  font-size: rem(16px);
  line-height: 1.5;
  text-align: center;
  width: 80%;
  height: auto;
  font-weight: bold;
}

.txt_blog_item{
  padding: 0% 0 0 0;
  margin: 5% auto 5% auto;
  color: #000;
  font-family: Firasansregular, sans-serif;
  font-size: rem(14px);
  line-height: 1.3;
  text-align: justify;
  width: 90%;
  height: auto;

}

.wrap_btn3{
  width: 80%;
  height: rem(40px);
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 10% auto 0 auto;
  text-align: center;
}

.btn3{
  width: rem(130px);
  height: rem(36px);
  border-radius: 4px;
  color: #fff;
  font-family: open-sans-light, sans-serif;
  display: inline-block;
  background: #ffa800;
  outline: none;
  text-decoration: none;
  border: none;
  position: relative;
  margin: 0;
  text-align: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
    &:hover{
    color: #000;
    cursor: pointer;
    }

    @include laptop{
      width: rem(180px);
      }
    @include tablet{
      margin: auto;
      display: block;
      width: rem(180px);
      }
    @include phone{
      margin: auto;
      display: block;
      width: rem(180px);
      }
}

.btn4{
  width: rem(130px);
  height: rem(36px);
  border-radius: 4px;
  color: #fff;
  font-family: open-sans-light, sans-serif;
  display: inline-block;
  background: #00abaa;
  outline: none;
  text-decoration: none;
  border: none;
  position: relative;
  margin: 0 10% 0 0;
  text-align: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
    &:hover{
    color: #000;
    cursor: pointer;
    }

    @include laptop{
      width: rem(180px);
      }
    @include tablet{
      margin: 0 auto 5% auto;
      display: block;
      width: rem(180px);
      }
    @include phone{
      margin: 0 auto 5% auto;
      display: block;
      width: rem(180px);
      }
}

.line_blog{
  margin: 40% auto 0 auto;
  width: 80%;
  display: none;
    @include laptop{
      margin: 10% auto 0 auto;
      display: block;
      }
    @include tablet{
      margin: 17% auto 0 auto;
      display: block;
      }
    @include phone{
      margin: 17% auto 0 auto;
      display: block;
      }

}


////////////////////////////////////////
//block7

.Block7{
  width: 100%;
  position: relative;
  height: 300px;
  margin: 0;
    @include laptop{
      height: 500px;
      }
    @include tablet{
      height: 500px;
      }
    @include phone{
      height: 480px;
      }
}


//////////////////////////
//Block8

.Block8{
  width: 100%;
  position: relative;
  height: 250px;
}

.partners{
  width: 98%;
  height: 30%;
  position: relative;
  display: inline-block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto 2% auto 0;
  text-align: center;
}

.img_partners{
  width: auto;
  height: 100%;
  position: relative;
  margin: auto 3%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: inline-block;
    @include laptop{
      }
    @include tablet{
      
      }
    @include phone{
      height: 70%;
      margin: 5% auto 0 auto;
      }
}


////////////////////////////////////////
//Footer


.footer__ff{
  width: 100%;
  top: 0px;
  height: rem(240px);
  margin-top:  rem(-240px);
  position: relative;
  background-color: #1f1f1e;
  display: block;
    @include laptop{
      height: rem(360px);
      margin-top:  rem(-360px);
      }
    @include tablet{
      height: rem(720px);
      margin-top:  rem(-720px);
      }
    @include phone{
      height: rem(720px);
      margin-top:  rem(-250px);
      }
}


.footerr{
  width: 100%;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  height: 96%;
  position: absolute;
  display: block;
  align-items: flex-start;
    @include laptop{
      display: block;
      }
    @include tablet{
      display: block;
      }
    @include phone{
      display: block;
      }
}

.footer_1{
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: relative;
  display: inline-block;
  margin: auto;
  height: 100%;
  width: 10%;
  float: left;
    @include laptop{
      
      }
    @include tablet{
      height: 50px;
      width: 100%;
      display: block;
      margin: 5% 0 0 0;
      }
    @include phone{
      height: 50px;
      width: 100%;
      display: block;
      margin: 5% 0 0 0;
      }
}

.footer_2{
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: relative;
  display: inline-block;
  margin: 0;
  height: 100%;
  width: 30%;
    @include laptop{
      
      }
    @include tablet{
      height: 300px;
      width: 100%;
      display: block;
      text-align: center;
      }
    @include phone{
      height: 250px;
      width: 100%;
      display: block;
      text-align: center;
      }
}

.footer_3{
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: relative;
  display: inline-block;
  margin: 0;
  height: 100%;
  width: 30%;
  vertical-align: top;
    @include laptop{
      
      }
    @include tablet{
      height: 135px;
      width: 55%;
      display: block;
      margin: 0 auto;
      }
    @include phone{
      height: 130px;
      width: 55%;
      display: block;
      margin: 0 auto;
      }
}

.footer_4{
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: relative;
  display: inline-block;
  margin: auto;
  height: 100%;
  width: 27%;
  float: right;
    @include laptop{
      
      }
    @include tablet{
      height: 180px;
      width: 100%;
      display: block;
      }
    @include phone{
      height: 180px;
      width: 100%;
      display: block;
      }
}

.f_1_text{
  padding: 0% 0 0% 0;
  margin: auto;
  color: #f9cb74;
  font-family: MyriadPro-Regular, sans-serif;
  font-size: rem(30px);
  line-height: 1.1;
  width: 80%;
  height: auto;
  text-align: center;
}

.f_1_text1{
  padding: 0% 0 0% 0;
  margin: auto;
  color: #b6b6b5;
  font-family: MyriadPro-Regular, sans-serif;
  font-size: rem(14px);
  line-height: 1.1;
  width: 80%;
  height: auto;
  text-align: center;
}

.menu_f{
  color: #6244cb;
  font-family: MyriadPro-Regular, sans-serif;
  font-size: rem(16px);
  line-height: 1.1;
    &:hover{
    color: #f9cb74;
    }
}

.menu_fff{
  padding: 0 0 2% 0;
}

.wrap_menu_ff{
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: relative;
  margin: 0 auto;
  height: 100%;
  width: 80%;
  justify-content: center;
}


.menu_ff{
  margin: 3% 0 0 0;
}

.footer_3_1{
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: relative;
  margin: 0;
  height: 20%;
  width: 100%;
  display: inline-block;
    @include laptop{
      
      }
    @include tablet{
      height: 24%;
      width: 100%;
      }
    @include phone{
      height: 24%;
      width: 100%;
      }
}

.f_3_1{
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: relative;
  margin: 0 0;
  height: 100%;
  width: 9%;
  color: #f9cb74;
  text-align: center;
  display: inline-block;
    @include laptop{
      
      }
    @include tablet{
      width: 6%;
      }
    @include phone{
      width: 6%;
      }
}

.f_3_2{
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: relative;
  margin: 0 0% 0 0%;
  height: 100%;
  width: 89%;
  display: inline-block;
//  text-align: center;

    @include laptop{
      
      }
    @include tablet{
      margin: auto 0% auto 0%;
      }
    @include phone{
      margin: auto 0% auto 0%;
      }
}

.f_3_2_text{
  color: #b6b6b5;
  font-family: MyriadPro-Regular, sans-serif;
  font-size: rem(14px);
  line-height: 1.1;
}

.wrap_img_f{
  height: rem(180px);
  width: 100%;
  position: relative;
  display: inline-block;
}

.img_f{
  height: auto;
  width: 90%;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: inline-block;
    @include laptop{
      width: 90%;
      
      }
    @include tablet{
      width: 65%;
      display: inline-block;

      }
    @include phone{
      display: inline-block;
      width: 90%;
      }
}




@import 'common/media';

