@mixin monitor {
  @media screen and (min-width: 992px) and (max-width: 1200px){
    @content;
  }
}


@mixin laptop {
  @media screen and (min-width: 768px) and (max-width: 992px){
    @content;
  }
}



@mixin tablet {
  @media screen and (min-width: 480px) and (max-width: 768px){
    @content;
  }
}

@mixin phone {
  @media screen and (max-width: 480px){
    @content;
  }
}


